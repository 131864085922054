import $ from 'jquery'

document.addEventListener('trix-initialize', (e) => {
  const $trix = $(e.target)

  $trix.attr('data-original-value', $trix.val())

  // Used in tests to know that the editor has been initialized
  $trix.attr('data-editor-initialized', true)

  window.addEventListener('beforeunload', function (e) {
    if (
      !$('body').hasClass('performing-action') &&
      $trix.is(':visible') &&
      $trix.data('original-value') != $trix.val()
    ) {
      ;(e || window.event).returnValue = false
      return false
    }
  })
})
