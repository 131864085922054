import addParamsToUrl from '../../../common/helpers/add_params_to_url'

document.addEventListener('DOMContentLoaded', (e) => {
  // If it is a split test this element would be present
  const splitTestPageIdEl = document.querySelector(
    '[data-behavior="add-split-test-page-id-to-url-params"]'
  )
  let splitTestPageId
  if (splitTestPageIdEl) {
    splitTestPageId = splitTestPageIdEl.getAttribute('data-split-test-page-id')
  }
  // If the user is logged in we will have the Simplero object id
  const simpleroObjectId = document.body.getAttribute('data-simplero-object-id')
  const links = document.getElementsByTagName('a')

  const landingPageId = document.body.getAttribute('data-landing-page-id')

  const upsellPurchaseId = document.body.getAttribute('data-upsell-purchase-id')

  // Link::AUTOMATION_REGEX
  const automationRegex = new RegExp(
    /^https?:\/\/[^,]*\.simplero\.[^,]*\/automations\/(\w+)\/(\w+)$/
  )
  // Link::LIST_REGEX
  const listRegex = new RegExp(
    /^https?:\/\/[^,]*\.simplero\.[^,]*\/lists\/(\w)\/(\w+)$/
  )
  // Link::TAG_REGEX
  const tagRegex = new RegExp(
    /^https?:\/\/[^,]*\.simplero\.[^,]*\/tags\/(\w+)\/(\w+)$/
  )

  for (const link of links) {
    const href = link.href
    // If it is a split test page or landing page and the link takes you to a purchase or product page add the split_test_page_id/landing_page_id so it is tracked
    if (
      (splitTestPageId || landingPageId) &&
      link.href &&
      (href.includes('purchase') || href.includes('product'))
    ) {
      const params = {
        ...(splitTestPageId && { split_test_page_id: splitTestPageId }),
        ...(landingPageId && { landing_page_id: landingPageId }),
      }
      const newHref = addParamsToUrl(href, params)
      link.href = newHref
    }

    // If the link matches the regex then add simplero_object_id for these action links to work
    if (
      simpleroObjectId &&
      (automationRegex.test(href) ||
        listRegex.test(href) ||
        tagRegex.test(href))
    ) {
      const newHref = addParamsToUrl(href, {
        simplero_object_id: simpleroObjectId,
        return_to: window.location.href,
      })
      link.href = newHref
      // Ignoring the open in a new tab setting as we will return the user back to the current URL
      link.removeAttribute('target')
    }

    // If purchase has upsell and the upsell is using a custom sales page
    if (href.includes('/upsells/')) {
      const newHref = addParamsToUrl(href, {
        upsell_purchase: upsellPurchaseId,
      })
      link.href = newHref
    }
  }
})
