import { getFn } from '../../common/helpers/api'
import { sitePages } from '~/routes'

/* can be extracted into a common site/page helper should the need arise */
async function refreshSection() {
  const sections = document.querySelectorAll(
    '[data-simplero-section-type=simplero_new_support_response_time]'
  )
  sections.forEach(async (section) => {
    const response = await getFn(
      sitePages.section.path({
        page_id: document.body.dataset.pageId,
        section_id: section.dataset.simpleroSectionId,
      })
    )()
    section.outerHTML = response
  })
}

window.addEventListener('DOMContentLoaded', () => {
  if (document.body.dataset?.simpleroRenderMode != 'live_edit') {
    setInterval(refreshSection, 30000)
  }
})
