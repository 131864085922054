const worksheet_listener = document.querySelector(
  '.js-enable-mark-as-complete-upon-completion'
)

if (worksheet_listener) {
  window.addEventListener('message', (e) => {
    if (e.data.type === 'worksheet:completed') {
      window.location.reload()
    }
  })
}
