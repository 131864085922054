import { whenSelectorPresent } from '~/common/helpers/dom'

async function initializeCollapsingComments() {
  const { initializeCollapsedComment } = await import('./collapse')
  initializeCollapsedComment()
}

whenSelectorPresent('#collapseComments, .comment').then(
  initializeCollapsingComments
)
