import $ from 'jquery'
import './main.scss'

function toggleCollapsibleText(sectionElem) {
  let $collapsible
  event.preventDefault()
  const $target = $(sectionElem)
  const $parent = $target.closest('.section--collapsible-text')
  const toggle_id = $target
    .parents('.section--collapsible-text__action-container')
    .data('toggle-id')
  // toggle_id will start with 1
  if (toggle_id) {
    $parent
      .find(
        `.section--collapsible-text__action-container[data-toggle-id='${toggle_id}']`
      )
      .toggleClass('collapsible-hidden')
    $collapsible = $parent.find(
      `.section--collapsible-text__collapsible-outer[data-toggle-id='${toggle_id}']`
    )
  } else {
    $parent
      .find('.section--collapsible-text__action-container')
      .toggleClass('collapsible-hidden')
    $collapsible = $parent.find('.section--collapsible-text__collapsible-outer')
  }

  if ($collapsible.data('renderMode') === 'live_edit') {
    return $collapsible.toggleClass('collapsible-hidden')
  } else {
    return $collapsible.css(
      'max-height',
      parseInt($collapsible.css('max-height'))
        ? 0
        : $collapsible
            .find('.section--collapsible-text__collapsible-inner')
            .css('height')
    )
  }
}

$(document).on(
  'click',
  '.section--collapsible-text__action-container a[data-toggle="collapse"]',
  (e) => toggleCollapsibleText(e.target)
)
