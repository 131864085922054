import './main.scss'

document.addEventListener('DOMContentLoaded', (e) => {
  let form = document.querySelector('form#stealme_form')
  if (form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault()
      let select = e.target.querySelector('select')
      window.location = select.value
    })
  }
})
