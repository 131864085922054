import $ from 'jquery'

// Form with autocomplete field.
document
  .querySelectorAll('input[data-behavior~="autocomplete-form-field"]')
  .forEach((field) => {
    let timeoutId

    // Get the search term
    const search = () => {
      if (field.value !== '') {
        // Need to do this with jQuery, with bare JS submit() it doesn't do the remove behaviour for whatever reason.
        $(field.closest('form')).submit()
      }
    }

    field.addEventListener('input', (e) => {
      clearTimeout(timeoutId)
      // debounce timeout
      timeoutId = setTimeout(search, 600)
    })
  })
