import $ from 'jquery'
import './main.scss'

// Read more/less on truncated content
$(document).on('click', '[data-action=body-truncate-toggle]', function (e) {
  e.preventDefault()
  $(this)
    .closest('.forum-post, .truncatable__container')
    .find('.body__truncated')
    .toggle()
  $(this)
    .closest('.forum-post, .truncatable__container')
    .find('.body__full')
    .toggle()
})
