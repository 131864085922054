import $ from 'jquery'

// Checkbox with "Other" input field

$(function () {
  $('.has-other input[type=checkbox]')
    .change(function () {
      const $this = $(this)
      const checked = $this.is(':checked')
      const div = $this.closest('.has-other')
      div.toggleClass('checked', checked)
      const input = div.find('input.other-input')
      input.prop('disabled', !checked)
      if (checked) {
        return input.focus()
      }
    })
    .change()

  $('.has-other')
  // Radio button with "Other" input field
  return $('.group-has-other input[type=radio]')
    .change(function () {
      const $this = $(this)
      const group = $this.closest('.group-has-other')
      const checked =
        group.find('.has-other input[type=radio]:checked')[0] != null
      const div = group.find('.has-other')
      div.toggleClass('checked', checked)
      const input = div.find('input.other-input')
      input.prop('disabled', !checked)
      if (checked) {
        return input.focus()
      }
    })
    .change()
})
