import $ from 'jquery'
import { processResponse } from './process_response'

// Asynchronous forms.
$(document).on(
  'submit',
  '.themed-content-root form[data-remote=true]',
  function (e) {
    e.preventDefault()
    const form = $(this)

    const event = $.Event('simplero:beforeSend')
    form.trigger(event, [form])

    form.find('input[type="submit"]').attr('disabled', true)

    if (event.result !== false) {
      const method = form.attr('method') || 'GET'
      const url = form.attr('action')
      const progressBar = form.find('.progress-bar')
      const data = $(form[0]).serializeArray()
      const dataType = 'JSON'

      return $.ajax({
        type: method,
        url,
        data,
        dataType,
        beforeSend(xhr) {
          progressBar?.addClass('half-progress-bar')
          progressBar?.removeClass('full-progress-bar')
        },
        success(data, _status, _xhr) {
          if (data.disableForm == null) {
            form.find('input[type="submit"]').attr('disabled', false)
          }
          progressBar?.removeClass('half-progress-bar')
          progressBar?.addClass('full-progress-bar')
          return processResponse(data)
        },
        error(xhr, status, error) {
          form.find('input[type="submit"]').attr('disabled', false)
          progressBar?.width(0)
          return alert(`Error: ${error}`)
        },
      })
    }
  }
)

// Asynchronous links.
$(document).on(
  'click',
  '.themed-content-root a[data-remote=true]',
  function (e) {
    let confirmation
    e.preventDefault()
    const link = $(this)

    const method = link.data('method') || 'POST'
    const url = link.attr('href')
    const dataType = 'JSON'
    const data = {}
    if (link.data('authenticity-token') != null) {
      data.authenticity_token = link.data('authenticity-token')
    }

    if ((confirmation = link.data('confirm'))) {
      if (!confirm(confirmation)) {
        return
      }
    }

    return $.ajax({
      type: method,
      url,
      dataType,
      data,
      success(data, _status, _xhr) {
        return processResponse(data)
      },
      error(xhr, status, error) {
        return alert(`Error: ${error}`)
      },
    })
  }
)
