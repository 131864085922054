import { whenSelectorPresentAll } from '~/common/helpers/dom'

let internationalPhoneSelector =
  '.simplero-custom-field--phone-number:not(.intl-tel-initialized), .custom-field--phone-number:not(.intl-tel-initialized)'

async function initializeValidator() {
  const { attachInternationalPhone } = await import('./main.js')
  document.querySelectorAll(internationalPhoneSelector).forEach((element) => {
    attachInternationalPhone(element, {
      customContainerClasses: 'signup-form-iti simplero-iti',
      countryCodeField: element.parentNode.querySelector(
        '.simplero-custom-field--phone-country, .custom-field--phone-country'
      ),
    })
  })
}

whenSelectorPresentAll(internationalPhoneSelector, initializeValidator)
