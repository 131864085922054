import { documentReady, find } from '~/common/helpers/dom'

// We use the header height in css for things like setting appropriate scroll-margin-top
const updateCalculatedHeaderHeightVariable = () => {
  const headerHeight = find('header')?.offsetHeight
  if (!headerHeight) return

  document.body.style.setProperty(
    '--header-height-calculated-by-js',
    `${headerHeight}px`
  )
}

documentReady(updateCalculatedHeaderHeightVariable)
window.addEventListener('resize', updateCalculatedHeaderHeightVariable)
