import $ from 'jquery'

$(function () {
  return $(
    '.simplero-section[id^=simplero-section-][data-simplero-delayed-rendering-delay]'
  ).each(function () {
    const $sectionElem = $(this)
    const delayInMilliseconds =
      $sectionElem.data('simpleroDelayedRenderingDelay') * 1000
    return setTimeout(
      () => $sectionElem.toggleClass('simplero-section--hidden'),
      delayInMilliseconds
    )
  })
})
