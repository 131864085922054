import $ from 'jquery'

// USAGE:
//
//   $(.,.).observe_field 0.2, ->
//     alert this.val()
//
$.fn.observe_field = function (frequency, events, callback) {
  if (events == null) {
    events = 'keyup'
  }
  frequency = frequency * 1000 // translate to milliseconds

  this.each(function () {
    const $this = $(this)
    let prev = $this.val()

    const check = function () {
      if (!$this.closest('html')[0]) {
        if (ti != null) {
          return clearInterval(ti)
        }
      } else {
        const val = $this.val()
        if (prev !== val) {
          prev = val
          return $this.map(callback) // invokes the callback on $this
        }
      }
    }

    const reset = function () {
      if (ti != null) {
        var ti
        clearInterval(ti)
        return (ti = setInterval(check, frequency))
      }
    }

    check()
    var ti = setInterval(check, frequency) // invoke check periodically

    // reset counter after user interaction
    $this.bind(events, reset)
  }) // mousemove is for selects
}
