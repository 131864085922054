import { whenSelectorPresent } from '~/common/helpers/dom'

let videoLinkSelector =
  "a.btn[href*='/uploads/asset/file/'][href*=mp4]:not([target='_blank'])"

const initializeVideoLinks = async () => {
  await import('./main')
  // Just a test hook
  document
    .querySelector(videoLinkSelector)
    .classList.add('video-lightbox-initialized')
}

whenSelectorPresent(videoLinkSelector).then(initializeVideoLinks)
